.App {
  min-height: 100vh;
  width: 100vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app__flex {
  background-color: #b4a095;
}

.app__dark {
  background-color: rgb(44, 44, 46);
}

.app-text-color__flex {
  color: 'rgb(0, 0, 0)'
}

.app-text-color__dark {
  color: 'rgb(255, 255, 255)'
}