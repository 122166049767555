.not-found-page {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    height: 100vh;
    visibility: visible;
    background-color: white;
}

.not-found-page__title {
    font-family: 'Inter', Arial, sans-serif;;
    font-size: 140px;
    font-weight: 400;
    line-height: 169px;
    letter-spacing: 0em;
    text-align: center;
    height: 169px;
    margin: 0;
    padding: 0 0 5px;
}

@media screen and (max-width: 475px) {
    .not-found-page__title {
        font-size: 80px;
        line-height: 96px;
        padding: 0 0 10px;
    }
}

.not-found-page__subtitle {
    font-family: 'Inter', Arial, sans-serif;;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 475px) {
    .not-found-page__subtitle {
        font-size: 12px;
        line-height: 14px;
    }
}

.not-found-page__link {
    text-decoration: none;
    color: #FF6838;
    text-align: center;
    margin: 184px 0 0;
    transition: opacity 0.8s;
    font-family: 'Inter', Arial, sans-serif;;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
}

.not-found-page__link:hover {
    cursor: pointer;
    opacity: 0.5;
}

@media screen and (max-width: 475px) {
    .not-found-page__link {
        font-size: 12px;
        line-height: 14px;
        margin: 284px 0 0;
    }
}

.not-found-page__content {
    display: flex;
    flex-direction: column;
}