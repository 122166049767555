/**
 * Этот файл Normalize.css относится к семантике проекта и обеспечивает согласованность
 * отображения элементов HTML во всех современных браузерах.
 * 
 * Normalize.css является альтернативой Reset CSS и предназначен для нормализации стилей
 * элементов HTML, делая их более согласованными между разными браузерами и обеспечивая
 * более четкое и управляемое базовое оформление для вашего веб-проекта.
 * 
 * Более подробную информацию о Normalize.css можно найти на сайте: https://necolas.github.io/normalize.css/
 */

/* Document
   ========================================================================== */

/**
 * 1. Устанавливаем нормализацию и корректировки маргина и отступов элементов.
 * 2. Убираем границы и отступы для html и body элементов.
 * 3. Корректируем шрифт для body элемента.
 * 4. Устанавливаем размеры элементов с такой же единицей измерения,
 *    что и размер шрифта для body элемента.
 * 5. Исправляем стили для preformatted элементов, чтобы они соответствовали общему
 *    виду текста.
 */
 html {
    line-height: 1.15; /* 1 */
    -webkit-text-size-adjust: 100%; /* 2 */
  }
  
  body {
    margin: 0; /* 3 */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 3 */
    font-size: 1rem; /* 3 */
    font-weight: 400; /* 3 */
    line-height: 1.5; /* 3 */
    color: #333; /* 3 */
    text-align: left; /* 3 */
    background-color: #fff; /* 3 */
  }
  
  /* Sections
     ========================================================================== */
  
  /**
   * Удаляем верхние отступы для h1 элементов, поскольку элементы h1 уже должны содержать
   * отступ в своем собственном стиле. См. https://html.spec.whatwg.org/multipage/sections.html#the-h1,-h2,-h3,-h4,-h5,-and-h6-elements
   */
  h1 {
    margin-top: 0;
  }
  
  /**
   * Настройка стилей для элемента hr для обеспечения разделения контента в разделах.
   */
  hr {
    box-sizing: content-box; /* 1 */
    height: 0; /* 1 */
    overflow: visible; /* 2 */
  }
  
  /**
   * Корректировка стиля для предварительно отформатированных элементов.
   */
  pre {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
  }
  
  /**
   * Установка базового размера шрифта и высоты строки для всех элементов dl и dd.
   */
  dl, dd {
    margin: 0; /* 1 */
  }
  
  /* Text-level semantics
     ========================================================================== */
  
  /**
   * Убираем декоративные стили подчеркивания для ссылок.
   */
  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }
  
  /**
   * Устанавливаем начальные стили для abbr элементов, которые могут использоваться для обозначения сокращений.
   */
  abbr[title] {
    text-decoration: underline dotted;
    cursor: help;
  }
  
  /**
   * Корректировка стилей для болдового текста элемента b.
   */
  b {
    font-weight: bolder;
  }
  
  /**
   * Устанавливаем начальные стили для кода элементов, предназначенных для отображения фрагментов кода.
   */
  code {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
  }
  
  /**
   * Устанавливаем начальные стили для курсивного текста элемента i.
   */
  i {
    font-style: italic;
  }
  
  /**
   * Устанавливаем начальные стили для жирного текста элемента strong.
   */
  strong {
    font-weight: bolder;
  }
  
  /**
   * Удаляем выделение текста по умолчанию в браузере.
   */
  ::selection {
    background-color: #3498db;
    color: #fff;
  }
  
  /* Фокус
     ========================================================================== */
  
  /**
   * Очищаем стили фокуса элементов ссылок и кнопок во всех браузерах.
   */
  a:focus, button:focus {
    outline: none;
  }
  
  /* Доступность
     ========================================================================== */
  
  /**
   * Очищаем стили, связанные с доступностью для элементов, не являющихся интерактивными.
   * См. https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
   */
  :not(:focus-visible) {
    outline: none;
  }
  
  /* Изображения
     ========================================================================== */
  
  /**
   * Стилизация для img элементов.
   */
  img {
    border-style: none; /* 1 */
    max-width: 100%; /* 2 */
    height: auto; /* 2 */
  }
  
  /* Элементы формы
     ========================================================================== */
  
  /**
   * Нормализация стилей для input элементов, кнопок и селектов.
   * Установка таких же начальных стилей для каждого элемента во всех браузерах.
   */
  input, button, select {
    margin: 0; /* 1 */
    font-family: inherit; /* 2 */
    font-size: inherit; /* 2 */
    line-height: inherit; /* 2 */
  }
  
  /**
   * Корректировка стилей для элементов input с типом "checkbox".
   */
  input[type="checkbox"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
  }
  
  /**
   * Корректировка стилей для элементов input с типом "radio".
   */
  input[type="radio"] {
    box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
  }
  
  /**
   * Корректировка стилей для input элементов, используемых внутри элементов label,
   * чтобы они соответствовали общему виду текста.
   */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    height: auto; /* 1 */
  }
  
  /**
   * Корректировка стилей для элементов input с типом "search".
   * Они должны отображаться как "textField".
   */
  input[type="search"] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
  }
  
  /**
   * Корректировка стилей для кнопок.
   */
  button {
    background-color: transparent; /* 1 */
    border: none; /* 2 */
    padding: 0; /* 3 */
  }
  
  /**
   * Корректировка стилей для select элементов.
   */
  select {
    -webkit-appearance: none; /* 1 */
    -moz-appearance: none; /* 1 */
    appearance: none; /* 1 */
    background-color: transparent; /* 2 */
    padding: 0; /* 3 */
  }
  
  /**
   * Убираем внутренние отступы для элементов select, приводя их к единому стилю во всех браузерах.
   */
  select::-ms-expand {
    display: none;
  }
  
  /**
   * Корректировка стилей для элементов select, которые включены, но неактивны.
   */
  select:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  
  /**
   * Корректировка стилей для элементов textarea.
   */
  textarea {
    overflow: auto; /* 1 */
    resize: vertical; /* 2 */
  }
  
  /* Таблицы
     ========================================================================== */
  
  /**
   * Нормализация стилей для таблиц и ячеек таблиц.
   * Убираем границы и отступы, чтобы таблицы выглядели более чисто.
   */
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  td,
  th {
    padding: 0;
  }
  
  /* Списки
     ========================================================================== */
  
  /**
   * Убираем маргины у списков, чтобы они не имели нежелательных отступов.
   */
  ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  /* Ссылки
     ========================================================================== */
  
  /**
   * Убираем стандартное подчеркивание у ссылок.
   */
  a {
    text-decoration: none;
  }
  
  /* Форматирование кода
     ========================================================================== */
  
  /**
   * Корректировка стилей для элементов code, чтобы они выглядели чище.
   */
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }
  
  /* Встраиваемые элементы
     ========================================================================== */
  
  /**
   * Убираем маргины у встроенных элементов, чтобы предотвратить избыточные отступы.
   */
  img,
  embed,
  object,
  video {
    display: block;
    max-width: 100%;
  }
  
  /* Аудио и видео
     ========================================================================== */
  
  /**
   * Корректировка отображения элементов audio и video, чтобы они были масштабируемыми.
   */
  audio,
  video {
    display: inline-block;
  }
  
  /* Шрифты
     ========================================================================== */
  
  /**
   * Нормализация размеров шрифтов и устранение аномального масштабирования в некоторых браузерах.
   */
  html {
    font-size: 16px;
  }
  
  /* Интерактивные элементы
     ========================================================================== */
  
  /**
   * Корректировка размера кнопок, чтобы они соответствовали общему стилю.
   */
  button,
  input,
  optgroup,
  select,
  textarea {
    font: inherit;
    margin: 0;
    padding: 0;
  }
  
  /**
   * Убираем внешние отступы элемента легенды, чтобы сохранить чистоту стиля.
   */
  fieldset {
    margin: 0;
    padding: 0;
  }
  
  /**
   * Корректировка размера элементов, чтобы они соответствовали общему стилю.
   */
  optgroup {
    font-weight: bold;
  }
  
  /**
   * Убираем внутренние отступы у элементов кнопки, чтобы сохранить единообразие.
   */
  button {
    overflow: visible;
  }
  
  /**
   * Убираем эффект рамки у кнопок, когда они активированы в различных браузерах.
   */
  button,
  select {
    text-transform: none;
  }
  
  /**
   * Убираем эффект налаживания текста при вводе в поля форм.
   */
  input[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }
  
  /**
   * Удаляем стандартные стили для несколько выбранных элементов в списке
   */
  select[multiple] {
    -webkit-appearance: none;
    background-image: none;
  }
  
  /* Наследование фиксации клавиатуры
     ========================================================================== */
  
  /**
   * Корректировка отступа для предотвращения отрицательных отступов при использовании
   * фиксации клавиатуры.
   * https://developer.mozilla.org/en-US/docs/Web/CSS/caret-color
   */
  html {
    scroll-padding-top: 3em; /* default */
  }
  
  /* Скрытие элементов
     ========================================================================== */
  
  /**
   * Скрытие внешнего счетчика для ссылок, когда они используются в качестве
   * якорей (URL-ссылок).
   */
  a {
    text-decoration: none;
    outline: none;
    -moz-tap-highlight-color: transparent;
  }
  
  /**
   * Скрытие стрелки для элементов, которые могут быть фокусируемыми.
   */
  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  
  /* Уменьшение скорости прокрутки
     ========================================================================== */
  
  html {
    scroll-behavior: smooth;
  }
  