.trainer-statistics {
    margin-top: 30px;
    width: 100%;
}

.trainer-statistics__title {
    text-align: center;
    font-size: 17px;
    font-weight: 500;
}

.trainer-statistics__container-columns {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    justify-content: space-between;
}

.trainer-statistics__container-columns__types {
    display: flex;
    flex-direction: column;
    width: 33.3%;
    text-align: left;
}

.trainer-statistics__container-columns__typesOfPayment {
    display: flex;
    flex-direction: column;
    width: 33.3%;
    text-align: center;
}

.trainer-statistics__container-columns__quantity {
    display: flex;
    flex-direction: column;
    width: 33.3%;
    text-align: right;
}

.trainer-statistics__container-columns__quantity{

}

.trainer-statistics__text {
    font-size: 15px;
    font-weight: 400;
    padding: 5px 0 0;
    margin: 0;

}