.choiser-studio {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.choiserStudioPage__link-page {
    color: rgb(241, 212, 206);
    text-decoration: none;
    font-family: 'et-book';
    letter-spacing: 13px;
    font-size: 20px;
    margin: 0;
    padding: 0 7px 0px 0px;
    display: table-cell;
    transition: opacity 0.7s;
}

.choiserStudioPage__link-page:hover {
    cursor: pointer;
    opacity: 0.7;
}

.choiserStudioPage__link-page_white {
    color: rgb(255, 255, 255) !important;
}

.choiserStudioPage__link-page_Dark {
    color: black;
    font-size: 32px;
    border: none;
    padding: 0 0 0px 15px;
    letter-spacing: 0;
    /* height: 39px; */
    margin: 0;
    border: none;
    transition: opacity 0.7s;
}

.choiserStudioPage__link-page_Dark:hover {
    cursor: pointer;
    opacity: 0.7;
}

.choiserStudioPage__link-page__description {
    color: rgb(241, 212, 206);
    font-family: "et-book";
    font-weight: 400;
    font-size: 10px;
    margin: 10px auto 0;
    letter-spacing: 1px;
    padding: 0 0 0 55px;
}

.choiserStudioPage__separator {
    background-color: rgb(241, 212, 206);
    width: 1px;
    height: 50px;
    margin: auto 0;
}

.choiserStudioPage__container{
    display: flex;
    flex-direction: row;
}

.choiserStudioPage__separator_white {
    background-color: rgba(255, 255, 255, 0.7) !important;
}