.trainer-payment-setting {
    margin: 0 0 30px 0;
    transition: opacity 0.7s;
}

.trainer-payment-setting:hover {
    cursor: pointer;
    opacity: 0.7;
}

.trainer-payment-setting__text {
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    padding: 0;
    margin: 0;
}