.input-select .css-1fdsijx-ValueContainer {
    padding: 0 !important;
}

.css-17uloxn-control{
    background-color: transparent !important;
}

.css-qbdosj-Input {
    font-family: 'Inter', sans-serif !important;
    background-color: transparent !important;
}

.css-3l3jbl-control {
    transition: background-color 0.7s;
}

.css-3l3jbl-control:hover {
    cursor: pointer;
    background-color: rgb(239, 239, 239);
}

.css-17uloxn-control:hover{
    cursor: pointer;
    background-color: rgb(239, 239, 239);
}