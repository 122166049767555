.trainer-info {
    margin: 30px 0;
    transition: opacity 0.7s;
}

.trainer-info:hover {
    cursor: pointer;
    opacity: 0.7;
}

.trainer-info__text {
    font-size: 15px;
    font-weight: 500;
    text-align: left;
}

.trainer-info__container-name {
    display: flex;
}

.trainer-info__text_name {
    padding-right: 5px;
}

.trainer-info__container-buttons {
    margin-top: 10px;
}