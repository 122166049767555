.choiserStudioPage {
    display: flex;
    background-color: rgb(44,44,46);
    flex-direction: column;
    height: 100vh;
    width: 100%;
}

.choiserStudioPage__link-container {
    height: 50px;
    display: flex;
    flex-direction: row;
    margin: 65vh auto 0;
    align-items: center;
    justify-content: center;
}

