.form-remove__container-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 10px 0;
}

.form-remove__title{
        text-align: center;
        font-family: 'Inter', Arial, sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 24px;
        line-height: 29px;
        color: #000000;
        margin: 0;
        padding: 0 0 24px;
        max-height: 24px;
}

@media screen and (max-width: 450px) {
    .formadd__title {
        padding: 0px 22px 35px 22px;
        font-size: 18px;
        line-height: 22px;
    }
    .form-remove__container-buttons {
        display: flex;
        flex-direction: column;
        height: 99px;
        justify-content: space-between;
        padding: 20px 10px 0;
    }
}