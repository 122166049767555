.text-color-flex {
    color: rgba(0, 0, 0) !important
}

.text-color-dark {
    color: rgba(255, 255, 255, 0.7)
}

.font-weight-bold {
    font-weight: 900;
}

.font-weight-medium {
    font-weight: 500;
}

.text-align-left {
    text-align: left;
}

.title {
    padding: 7px 0;
    margin: 0;
    font-weight: 700;
    font-size: 17px;
}

.text-align-left {
    text-align: left;
}

.text-align-right {
    text-align: right;
}

@media screen and (max-width: 700px) {
    .title {
        font-size: 15px;
    }
}