.input-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 0 7px;
    margin-top: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.input-with-label-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.input {
    width: 100%;
    border: 0;
    padding: 0 !important;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background-color: transparent;
    color: #000000;
}

.input__label {
    text-align: left;
    margin: 0 !important;
    padding: 0 0 0 !important;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 17px;
    color: #000000;
}

@media screen and (max-width: 790px) {
    .input__label {
        font-size: 15px;
    }
}

.input::placeholder {
    padding: 5px 0;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 17px;
    color: #706F6F;
}

@media screen and (max-width: 790px) {
    .input {
        padding: 5px 0;
        font-size: 15px;
        color: #000000;
    }

    .input::placeholder {
        padding: 3px 0;
        font-size: 15px;
    }
}

@media screen and (max-width: 790px) {
    .input-with-label-container {
       
    }
}

.input__text-error {
    font-size: 12px;
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    padding: 10px 0 0;
    color: rgb(0, 0, 0);
}

@media screen and (max-width: 790px) {
    .input__text-error {
        font-size: 10px;
        padding: 3px 0 0;
    }
}