.time-table-list-element {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0;
    margin: 0;
}

.time-table-list-element:nth-child(2) {
    padding-top: 5px;
}

.time-table-list-element_dateTrain {
    font-weight: 900;
}

.time-table-list-element__text {
    font-family: 'apple', Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    margin: 0;
}