.non-membership-visits-page {
    min-height: '100%';
    width: 100vw;
    display: flex;
    flex: 1;
    justify-content: center;
}

.non-membership-visits-page__loader {
    display: flex;
    justify-content: center;
    align-items: center;
}

.non-membership-visits-page__visits{
    width: 100vw;
}

.non-membership-visits-page__visit{
    width: '100%';
}

.non-membership-visits-page__text {
    margin: 0;
    font-family: 'et-book', Arial, sans-serif;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 3px;
    padding: 0;
}