.form-two-text-button {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: '#fff';
}

.form-two-text-button__button {
    margin: 10px 0;
}

.form-two-text-button__container-buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
}