.header-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 30px 0 0 0;
}

.header-page__role {
    color: rgb(255,255,255, 0.7) !important;
    text-decoration: none;
    font-family: 'et-book';
    letter-spacing: 10px;
    font-size: 12px;
    margin: 0;
    padding: 8px 7px 10px 0px;
    display: table-cell;
    transition: opacity 0.7s;
}