.input-select__container {
    display: flex;
    flex-direction: row;
    justify-content: start;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 0 7px;
    margin-top: 0px;
    width: 100%;
}

.input-select__container_flex-row {}

.input-select {
    border: 0;
    padding: 0 !important;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 17px;
    color: #000000;
    text-overflow: ellipsis;
    text-align: right !important;
    overflow: hidden;
    white-space: nowrap;
    background-color: transparent;
    width: 100%;
    direction: rtl;
}

.input-select::placeholder {
    color: #000000;
}

@media screen and (max-width: 790px) {
    .input-select {
        font-size: 15px;
    }
}

.input-select__option {
  
}

.input-select__label {
    text-align: left;
    border: 0;
    margin: 0;
    padding: 0 !important;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 17px;
    color: #000000;
}

.input-date::placeholder {
    color: #000000;
}

@media screen and (max-width: 790px) {
    .input-date {
        font-size: 15px;
    }

    .input-select__label {
        font-size: 15px;
    }
}