.circle-indicator{
    border-radius: 50%;
    width: 11px;
    height: 11px;
    margin: 0 5px;
}

.circle-indicator_green{
    background-color: rgba(28, 196, 28, 0.3);
}

.circle-indicator_yellow {
    background-color: rgba(255, 255, 0, 0.252) !important;
}

.circle-indicator_red {
    background-color: rgba(255, 0, 0, 0.3);
}