.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 900px;
    height: 100vh;
}

@media screen and (max-width: 900px) {
    .login {
        width: 90vw;
    }
}

.login__title {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    padding: 0 0 150px;
    margin: 0;
}

.login__container-text-password-recover {
    display: flex;
    flex-direction: row;
}

.login__container-text-password-recover-text {
    padding: 0 5px 0;
    margin: 0;
    font-size: 14px;
}

.login__container-text-password-recover-link {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid blue;
    color: rgba(0, 0, 255, 0.689);
    font-size: 14px;
    font-family: 'Inter', 'Helvetica Neue', Helvetica, Arial, ;
    transition: font-size 0.7s;
}

.login__container-text-password-recover-link:hover {
    cursor: pointer;
    font-size: 17px;
}

.login__button {
    margin: 80px 0 30px;
}

.login__validation-text {
    min-height: 30px;
    font-size: 12px;
}