.time-table {
    width: 100%;
}

.time-table__hall-selector-container {
    /* padding: 0 2%; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.time-table__hall-selector {
    min-width: 70px;
}

.time-table__hall-selector-text {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    padding: 0 10px 0 0;
}