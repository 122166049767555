.list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
}

@media screen and (max-width: 599px) {
    .list {
        padding-top: 0px;
    }
}

.list__element {
    background-color: transparent;
    width: 81%;
    max-width: 700px;
    min-height: 30px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid black;
    margin: 0;
    padding: 10px 0 0;
    display: flex;
    flex-direction: column;
    transition: background-color 0.8s;
}

.list__element:hover {
    cursor: pointer;
    background-color: rgb(0, 0, 0, 0.1) !important;
}

@media screen and (max-width: 599px) {
    .list__element {
        min-height: 10px;
        margin: 0;
    }

    .list__element:hover {
        background-color: transparent !important;
    }
}