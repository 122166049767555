.formadd {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(37, 37, 37, 0.5) !important;
    /* Прозрачный фон */
    backdrop-filter: blur(10px) !important;
    /* Эффект размытия для матового стекла */
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.2s, opacity 0.2s linear;
}

.formadd__text-fill {
    font-size: 18px;
}

.formadd__text-fill-container {
    padding: 10px 0;
}

.formadd_show {
    visibility: visible;
    opacity: 1;
    /* transition: visibility 0.5s, opacity 0.5s; */
    z-index: 9999;
}

.formadd_overlay {}

.formadd__title {
    text-align: center;
    font-family: 'Inter', Arial, sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin: 0;
    padding: 0 0 24px;
    max-height: 24px;
}

.formadd__text-input-info {
    margin: 5px 36px 0;
    height: 30px;
    border: 0;
    color: rgba(0, 0, 0, 0.5);
    padding: 0 0 0 0;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    opacity: 1;
}

.formadd__text-error {
    margin: 5px 36px 0;
    height: 30px;
    border: 0;
    /* color: rgba(170, 54, 54, 0.5); */
    color: rgba(0, 0, 0, 0.5);
    padding: 0 0 0 0;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    opacity: 1;
}

.formadd__text-error_show {
    color: rgba(170, 54, 54, 0.5);
}

.formadd__set {
    display: flex;
    flex-direction: column;
}

.formadd__select-option {}

.formadd__input {
    margin: 0 36px 0;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0 0 5px 0;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 358px;
    max-height: 14px;
    background-color: transparent !important;
    text-align: left;
}

.formadd__input::placeholder {
    color: #000000;
}

.formadd__input-text {
    font-size: 14px;
    color: black;
    padding: 0 15px 0 0;
    margin: 0;
    position: absolute;
    left: 23px
}

.formadd_abonement {
    margin-top: 10px;
    position: static;
    display: flex;
    visibility: visible;
    box-shadow: none !important;
    opacity: 1;
    background-color: transparent;
    z-index: 1 !important;
}

.formadd__input-time-and-date {
    text-align: right;
    min-width: 224px;
    min-height: 20.8;
}

.formadd__input-select {
    max-height: max-content !important;
    line-height: 14px;
    text-align: left;
    padding: 0 0 3px 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    background-image: url('./../../../../assets/images/arrow-down-black.svg');
    background-size: 20px 20px;
    background-position: right center;
    background-repeat: no-repeat;
    background-color: transparent !important;
}

.formadd__input-select_abonement {
    margin: 0 auto 0 !important;
    width: 282px !important;
}

.formadd__input-plaiceholder-container {
    display: flex;
    flex-direction: row;
}

.formadd__input-conainer {
    display: flex;
    flex-direction: column;
    margin: 0;
}

.formadd__form {
    /* max-height: 830px; */
    width: 430px;
    /* background: #FFFFFF; */
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.formadd__form_abonement {
    box-shadow: none;
    position: relative;
    background-color: transparent;
    z-index: 1 !important;
}

.formadd__content {
    width: 90%;
    max-width: 430px;
    min-height: 170px;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    border-radius: 30px;
    padding: 30px;
}

.formadd__close-button {
    margin-left: auto;
    padding-bottom: 10px;
    width: 32px;
    height: 32px;
    
    right: -39px;
    top: -39px;
    background-image: url(./../../../../assets/images/CloseIcon.svg);
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    border: 0;
}

.formadd__close-button:hover {
    opacity: 0.6;
    cursor: pointer;
}

.formadd__close-button_hide {
    visibility: hidden;
    pointer-events: none;
}

.formadd__checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: transparent;
    height: 36px;
    border-radius: 0 9px 9px 0;
    /* padding: 0 30px 0 29px; */
}

.formadd__checkbox-button {
    width: 36px;
    height: 20px;
    border-radius: 20px;
    border: none;
    background-repeat: no-repeat;
    background-position: top -3px left -4px;
    cursor: pointer;
    background-image: url(./../../../../assets/images/tumb__COLOR_tumbler-on-2.svg);
    transition: background-position 1s, background-color 1s, background-image 1s, box-shadow 2s;
}

.formadd__checkbox-button-name {
    font-family: 'Inter', Arial, sans-serif;
    ;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 0 0 14px;
    margin: 0;
}

.formadd__checkbox-button-name-two {
    padding-right: 14px;
}

.formadd__checkbox-button_on {
    background-position: top -3px right -4px;
    background-color: rgb(28, 196, 28, 0.3) !important;
    /* box-shadow: 0px 0px 10px 5px rgba(28, 196, 28, 0.6); */
}

.formadd__checkbox-button_on_flex {}

.formadd__checkbox-button_off {
    background-color: #EBEBEB;
    background-position: top -3px left -4px;
    transition: background-position 1s, background-color 1s;
}

.formadd__checkbox-button_off_flex {
    background-image: url(./../../../../assets/images/tumbler-flex.svg);
    background-color: transparent;
}

.formadd__checkbox-button_flex {
    background-image: url(./../../../../assets/images/tumbler-flex.svg);
    background-color: transparent;
    border: 1px solid black;
}

.formadd__checkbox_super-admin {
    padding: 0;
    margin: 0;
}

.formadd__button {
    width: 358px;
    height: 50px;
    background-color: #000000;
    border: 0;
    border-radius: 2px;
    margin: 35px auto 0;
    font-family: 'Inter', Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    padding: 0;
    transition: background-color 0.8s;
    border-radius: 50px;
}

.formadd__button:hover {
    background-color: rgba(0, 0, 0, 0.8);
    cursor: pointer;
}

.formadd__button-under-text {
    text-align: center;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.formadd__button_flex {
    color: white;
    background-color: #000000;
}

.formadd__button_flex:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

@media screen and (max-width: 450px) {
    .formadd__button {
        width: 238px;
        height: 46px;
        margin: 35px auto 0;
        padding: 0;
        font-size: 14px;
        line-height: 17px;
    }
}

.formadd__button:disabled {
    opacity: 0.15;
}

@media screen and (max-width: 769px) {
    .formadd__checkbox-button-name {
        padding: 0 0 0 15px;
    }
}

@media screen and (max-width: 580px) {
    .formadd__checkbox {
        /* padding: 0 20px 0 19px; */
        background-color: transparent;
    }
}

@media screen and (max-width: 590px) {
    .formadd__close-button {
        width: 20px;
        height: 20px;
        margin-bottom: 16px;
        right: 0px;
    }
}

@media screen and (max-width: 450px) {
    .formadd__content {
        width: 282px;
    }
}

@media screen and (max-width: 450px) {
    .formadd__content {
        width: 282px;
    }
}

@media (max-width: 900px) {
    .formadd__input-text {
        right: 39px
    }
}

@media screen and (max-width: 450px) {
    .formadd__input {
        margin-left: 22px;
    }
}


@media screen and (max-width: 450px) {
    .formadd__text-error {
        margin-left: 22px;
    }
}

@media screen and (max-width: 450px) {
    .formadd {
        justify-items: center;
    }

    .formadd__text-input-info {
        margin-left: 22px;
    }

    .formadd__title {
        padding: 0px 22px 35px 22px;
        font-size: 18px;
        line-height: 22px;
    }
}