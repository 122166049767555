.calendar {
    background-color: transparent;
    /* border-radius: 30px; */
    width: 100%;
    min-height: 500px;
}

.rbc-calendar {
    /* width: 100%; */
    /* padding: 30px; */
    /* min-height: 500px; */
}

.rbc-calendar span,
.rbc-calendar button {
    color: rgba(0, 0, 0, 1) !important;
}

.react-big-calendar-week-view .rbc-event {
    font-family: Arial, sans-serif;
    font-size: 1cqmax !important;
    color: white;
    /* Другие настройки шрифта */
}

/* Шрифт заголовка в ячейке */
.rbc-event-label {
    color: rgb(255, 255, 255);
    font-size: 1cqmax !important;
}

/* Шрифт текста в ячейке */
.rbc-event-content {
    font-size: 1cqmax !important;
}

/* Сама ячейка инф в расписании */
.rbc-event {
    /* background-color: rgb(97, 89, 84) !important; */
    border: none !important;
    width: 100% !important;
}

.rbc-timeslot-group {
    min-height: 140px !important;
}

@media (max-width: 590px) {
    .rbc-timeslot-group {
        min-height: 80px !important;
    }
}

/* контейнер .rbc-event */
.rbc-events-container {
    margin-right: 3px !important;
}

/* цвет фона активной недели */
.rbc-today {
    background-color: rgba(0, 0, 0, 0.251) !important;
    background-color: transparent !important;
    /* Другие настройки цвета фона */
}

/* цвет текста для всего календаря для Дарк (добавлять к .trainig-calendar-container) */
.trainig-calendar-container_dark {
    color: rgba(255, 255, 255, 0.551) !important;
}

/* цвет рамки для Дарк */
.rbc-time-content_dark {
    border: 2px solid rgba(255, 255, 255, 0.551) !important;
}

.rbc-label {
    font-family: 'Inter', sans-serif;
}

.rbc-button-link {
    font-family: 'Inter', sans-serif;
}

.rbc-btn-group {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 500;
    padding: 0 !important;
}

.rbc-toolbar-label {
    font-family: 'Inter', sans-serif;
    width: 100%;
}

.rbc-day-slot {
    font-family: 'Inter', sans-serif;
}

@media (max-width: 900px) {
    .rbc-calendar {
        /* transform: rotate(90deg);
        transform-origin: top left;
        width: 100vh;
        height: 100vw; */
    }
}

.rbc-time-slot {
    /* z-index: 999; */
}