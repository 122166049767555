.trainer-page {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.trainer-info__text {
    padding: 0;
    margin: 0;
    font-size: 12px;
    font-weight: 500;
}