body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('./normalize.css');
@import url('./assets/fonts/fonts.css');

/* Страницы */
@import url('./App.css');
@import url('./Pages/ChoiserStudioPage/style/choiserStudioPage.css');
@import url('./Pages/ClientPageStudio/style/page__subtitle.css');
@import url('./Pages/ClientsAndEmployees/components/Switcher/switcher-super-admin.css');
/* @import url('./Pages/ClientsAndEmployees/components/List/style/list.css'); */
@import url('./Pages/MainPage/style/main-page.css');
@import url('./Pages/ClientsAndEmployees/styles/clients-and-employees.css');
@import url('./Pages/NotFoundPage/style/not-found-page.css');
@import url('./Pages/ClientPage/style/client-page.css');
@import url('./Pages/ClientPage/components/forms/FormAbonement/components/FormChoiceAbonementCRUD/style/form-choice-crud.css');
@import url('./Pages/TimeTablePage/style/time-table.css');
@import url('./Pages/ClientListOnTrainingPage/style/client-list-on-training-page.css');
@import url('./Pages/TrainerPage/styles/trainer-page.css');
@import url('./Pages/TrainerPage/components/TrainerInfo/styles/trainer-info.css');
@import url("./Pages/TrainerPage/components/TrainerStatistics/styles/trainer-statistics.css");
@import url("./Pages/TrainerPage/components/TrainerPaymentSettings/styles/trainer-payment-setting.css");
@import url('./Pages/TrainingSettingPage/styles/training-setting.css');
@import url('./Pages/SettingPage/styles/setting-page.css');
@import url('./Pages/AbonementSettingPage/styles/abonement-setting-page.css');
@import url('./Pages/NonMembershipVisit/style/non-membership-visits-page.css');

/* Компоненты */
@import url('./commonComponents/pages/header/header-page.css');
@import url('./commonComponents/pages/choiserStudio/choiser-studio.css');
@import url('./commonComponents/NavTab/nav-tab.css');
@import url('./commonComponents/buttons/ButtonImg/button-img.css');
@import url('./commonComponents/buttons/ButtonWithBorder/styles/button-with-border.css');
@import url('./commonComponents/buttons//ButtonFileUpload/styles/style.css');
@import url('./commonComponents/search/SearchForm/style/search-form.css');
@import url('./commonComponents/loaders/Loader/styles/loader.css');
@import url('./commonComponents/loaders/Preloader/Preloader.css');
@import url('./commonComponents/LoaderPage/styles/loader-page.css');
@import url('./commonComponents/forms/FormAdd/style/formadd.css');
@import url('./commonComponents/forms/FormTwoTextButton/styles/styles.css');
@import url('./commonComponents/inputs/InputText/styles/input.css');
@import url('./commonComponents/inputs/InputSelect/styles/input-select.css');
@import url('./commonComponents/inputs/InputDate/style/input-date.css');
@import url('./commonComponents/Login/style/login.css');
@import url('./commonComponents/indicators/circle-indicator.css');
@import url('./commonComponents/forms/FormRemove/styles/form-remove__container-buttons.css');
@import url('./commonComponents/pages/AbonementNameAndIndicator/style/abonement-info.css');
@import url('./commonComponents/pages/ClientInfo/style/client-info.css');
@import url('./commonComponents/Calendar/styles/calendar.css');
@import url('./commonComponents/inputs/InputDateTimePicker/styles/input-date-time-picker.css');
@import url('./commonComponents/pages/TimeTable/TimeTableListElement/style/time-table-list-element.css');
@import url('./commonComponents/pages/MonthAndYearSelector/style/month-and-year-selector.css');
@import url('./commonComponents/inputs/InputReactSelect/styles/input-select.css');
@import url('./commonComponents/loaders//LoaderNames/styles/loader-names.css');

/* Общие стили */
@import url('./commonStyles/commonStyles.css');
@import url('./commonStyles/list.css');
@import url('./commonStyles/commonStylesText.css');
@import url('./commonStyles/pageStyles.css');