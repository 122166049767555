.client-list-on-training-page{
    display: flex;
    flex-direction: column;
    margin: 30px 0 0;
}

.client-list-on-training-page__title{
    font-size: 17px;
}

.client-list-on-training-page__search-form{
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 700px) {
    .client-list-on-training-page__title{
        font-size: 15px;
    }
}