.switcher-super-admin {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    padding-top: 17px;
}


@media screen and (max-width: 700px) {
    .switcher-super-admin{
        font-size: 10px;
    }
}