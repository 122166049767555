.input-date__container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 0 7px;
    margin-top: 0px;
    width: 100%;
}

.input-date {
    border: 0;
    padding: 0 !important;
    text-align: right;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background-color: transparent;
}

.input-date::placeholder {
    color: #000000;
}

@media screen and (max-width: 790px) {
    .input-date {
        font-size: 15px;
    }
}