.input-date-time-picker {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px 0 7px;
    margin-top: 0px;
}

.input-date-time-picker::placeholder {
    padding: 5px 0 10px;
    font-family: 'Inter', Arial, sans-serif;
    font-weight: 400;
    font-size: 17px;
    color: #706F6F;
}

@media screen and (max-width: 790px) {
    .input-date-time-picker {

        font-size: 15px;
        color: #000000;
    }

    .input-date-time-picker::placeholder {
        padding: 3px 0;
        font-size: 15px;
    }
}

.date-picker {
    background-color: transparent;
    font-family: 'Inter', Arial, sans-serif;
    font-size: 15px;
    text-align: right;
    padding: 5px 5px;
    border-radius: 15px;
    /* border: 3px solid #7575755f; */
    border: none !important;
    margin: 0 auto !important;
}