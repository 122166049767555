.nav-tab {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    width: 500px;
    padding: 0 15px 10px 15px;
    /* margin: 0 auto 10px; */
    margin-bottom: 10px;
    /* margin-left: calc((100vw - 515px)/2);
    margin-right: calc((100vw - 515px)/2); */
    margin-left: calc((100% - 515px)/2);
    margin-right: calc((100% - 515px)/2);
    border: none;
    justify-content: space-between;
    /* background-color: rgba(255, 255, 255, 0.512); */
    background-color: rgba(37, 37, 37, 0.282) !important;
    /* Прозрачный фон */
    backdrop-filter: blur(5px) !important;
    /* Эффект размытия для матового стекла */
    border-radius: 50px;
    transition: bottom 0.7s;
}

.nav-tab_unvisible {
    /* position: absolute !important; */
    bottom: -220px;
}

.nav-tab__img-personal{
    width: 30px !important;
}


@media screen and (max-width: 599px) {
    .nav-tab {
        max-width: calc(100vw - 78px);
        padding: 0 15px 10px 15px;
        margin: 0 20px 10px;
    }
}