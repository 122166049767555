.button-file {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    border: 2px solid #000000;
    padding: 3px 10px;
    min-height: 30px;
    max-height: 30px;
    min-width: 120px;
    max-width: 250px;
    transition: background-color 0.7s, border-color 0.7s, opacity 0.7s;
    margin: 0 auto;
    transition: box-shadow 0.7s;
}

.button-file_margin {
    margin: 15px auto 0 !important;
}

.button-file:hover {
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 790px) {
    .button-file {
        padding: 10px 20px;
        border: px solid #000000;
        font-size: 12;
        padding: 3px 10px;
        min-width: 120px;
    }
}

/* текст */
.button-file__text {
    font-size: 17px;
    font-weight: 500;
    text-align: center;
    padding: 0 !important;
    margin: 0;
}

/* disabled */
.button-file__disabled {
    opacity: 0.3;
}

.button-file__disabled:hover {
    cursor: default !important;
    background-color: transparent !important;
    border-color: black !important;
}