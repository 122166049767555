.search-form__container {
    width: 500px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 30px auto;
    padding: 15px 5px;
    border: 1px solid black;
    border-radius: 50px;
}


.search-form {
    margin: 0 auto 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.search-form__input {
    background-color: transparent;
    border: none;
    color: #000000;
    font-family: 'Inter', Arial, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.search-form__input::placeholder {
    color: #000;
    padding-left: 10px;
}

.search-form__input-button {
    background-color: transparent;
    height: 100%;
    border: none;
    padding: 0 10px;
    transition: opacity 1s;
}

.search-form__input-button:hover {
    opacity: 0.5;
    cursor: pointer;
}


.search-form__input-button_on-phone {
    margin: 0;
    /* padding: 0; */
}

.search-form__img {
    background-color: transparent;
    width: 22px;
    /* padding-right: 30px; */
}

.search-form__icon {
    color: #A0A0A0;
    width: 15px;
    background-color: rgb(180, 160, 149);
    border-radius: 9px 0 0 9px;
    height: 100%;
    padding: 0 10px 0 19px;
    background: url(./../../../../assets//images/search-normal-1-svgrepo-com.svg) #F9F9F9 50% no-repeat;
}

.search-form__container-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
    width: 100%;
}

.search-form__checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgb(180, 160, 149);
    height: 72px;
    border-radius: 0 9px 9px 0;
    padding: 0 30px 0 29px;
}

@media screen and (max-width: 580px) {
    .search-form__checkbox {
        padding: 0 20px 0 19px;
        margin: 46px 0 0;
        height: max-content;
        background-color: white;
    }
}

.search-form__button {
    width: 36px;
    height: 20px;
    /* border-radius: 20px; */
    border: none;
    background-repeat: no-repeat;
    cursor: pointer;
    background-image: url(./../../../../assets/images/tumb__COLOR_tumbler-on-2.svg);
    transition: background-position 1s, background-color 1s;
}

.search-form__button-name {
    font-family: 'Inter', Arial, sans-serif;
    ;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 0 0 14px;
    margin: 0;
}

@media screen and (max-width: 769px) {
    .search-form__button-name {
        padding: 0 0 0 15px;
    }
}

.search-form__button_on {
    background-color: #2BE080;
    background-position: top -2px right -4px;
}

.search-form__button_off {
    background-color: rgb(180, 160, 149);
    background-position: top -2px left -4px;
    transition: background-position 1s, background-color 1s;
}


@media screen and (max-width: 769px) {
    .search-form {
        width: calc(100vw - 60px);
        flex-direction: column;
    }

    .search-form__container {
        border: 2px solid black;
        border-radius: 15px;
        width: calc(100vw - 140px);
        padding: 5px 5px;
    }
}

@media screen and (max-width: 430px) {
    .search-form__icon {
        background-image: none;
        width: 0;
        padding: 0;
    }
}