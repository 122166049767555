.button-img {
    border: 0;
    padding-top: 10px;
    margin: 0;
    font-size: 16px;
    text-align: left;
    padding-left: 0;
    font-weight: bold;
    width: 40px;
    background-color: transparent !important;
    transition: opacity 0.7s;
}

.button-img:hover {
    cursor: pointer;
    opacity: 0.7;
}

.button-img__img {
    width: 100%;
}

@media screen and (max-width: 599px) {
    .button-img {
        width: 35px;
    }
}