.background-color__flex {
    background-color: #b4a095;
}

.background-color__dark {
    background-color: rgb(44, 44, 46);
}

.gap__17px {
    display: grid;
    gap: 17px 0;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.margin-vertical__10px {
    margin: 10px 0;
}

.margin-top__10px {
    margin-top: 10px;
}

.margin-top__30px {
    margin-top: 30px !important;
}

.margin-padding-0 {
    margin: 0;
    padding: 0;
}

.display-flex {
    display: flex;
}

.flex-direction_column {
    flex-direction: column;
}

.justify-content_space-between {
    justify-content: space-between;
}

.container-padding-top {
    padding-top: 17px;
}

.width__100 {
    width: 100vw;
}

.width_100procents {
    width: 100%;
}

