.client-info {
    margin: 0;
}

.client-info__quantityVisits {
    font-family: 'bebas-regular';
    font-weight: 400;
    margin: 0;
    font-size: 70px;
    padding: 0;
    height: 70px;
    position: absolute;
    right: 0px;
    top: 80px;
}

.client-info__quantityVisits_groupe {
    font-size: 150px;
    height: 170px;
    /* padding-right: 10px; */
    right: 30px;
    top: -29px;
    border: none !important;
    padding: 0 !important;
}

.client-info__indicator_client-page {
    margin-left: 5px;
}

.client-info__container {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.client-info__container:nth-child(3) {
    margin-bottom: 10px;
}

.client-info__container-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    margin: 0 auto;
    width: 90vw;
    max-width: 700px;
    min-width: 279px;
}

.client-info__container-main-quantityVisits {
    display: flex;
    flex-direction: row;
}

.client-info__container-main-info {
    display: flex;
    flex-direction: column;
    margin: 0 0 10px 0;
}

.client-info__container-info {
    display: flex;
    flex-direction: row;
}

.client-info__container-indicator {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.client-info__container-indicator-abonement {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.client-info__container-indicator-abonement_admin {
    margin-left: 10px;
    margin-top: 6px;
}

.client-info__button-abonements {
    border: none;
    margin: 0;
    padding: 0;
}

.about-client-page__text-active-visits {
    font-family: 'apple', Arial, sans-serif;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0;
    text-align: center;
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
    background-color: black;
    border-radius: 10px;
    padding: 3px 10px;
    width: 120px;
}

.client-info__text {
    margin: 0;
    font-family: 'et-book', Arial, sans-serif;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0 0 3px;
    padding: 0;
}